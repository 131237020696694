import { useEffect } from 'react';
import { useStateValue } from '../providers/StateProvider';
import jwt from 'jsonwebtoken';

export const useUserInfo = () => {

    const [_, dispatch] = useStateValue();

    useEffect(() => {
        if (localStorage.getItem("token")) {
            const info = jwt.decode(localStorage.getItem("token"))?.result
            if (info?.admin)
                dispatch({
                    type: 'ADD_ADMIN_INFO',
                    item: {
                        id_admin: info?.id,
                        nombre: info?.nombre,
                        usuario: info?.usuario,
                        id_propietario: info?.id_propietario
                    }
                });
            else
                dispatch({
                    type: 'ADD_USER_INFO',
                    item: {
                        nombres: info?.nombres,
                        id: info?.id,
                        apellido_paterno: info?.apellido_paterno,
                        apellido_materno: info?.apellido_materno,
                        correo: info?.correo,
                        cod_pais: info?.cod_pais,
                        telefono: info?.telefono,
                        verificado: info?.verificado
                    }
                })
        }
    }, []);
}