import React from "react";
import { Table } from "antd";
import { usePostRequest } from '../../../../hooks';
import { useStateValue } from "../../../../providers/StateProvider";
import { DeleteMarca } from "../deletes/DeleteMarca";

export const MarcasTable = () => {
  const [{ triggerMarcas }] = useStateValue();
  const { data, loading } = usePostRequest("/products/marcas", [triggerMarcas]);

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Eliminar",
      dataIndex: "id",
      key: "id",
      render: (text, records) => (
        <DeleteMarca id={text} />
      ),
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      loading={loading}
    />
  )
}
