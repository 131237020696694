import React, { useState, useEffect } from "react";
import { Table, Skeleton } from "antd";
import { usePostRequest } from '../../../../hooks';
import moment from 'moment';
import { useStateValue } from "../../../../providers/StateProvider";
import { DeleteProducto } from "../deletes";
import ModalPublish from "../modals/ModalPublish";
import ModalArrived from "../modals/ModalArrived";
import { PauseProduct } from "../actions/PauseProduct";
import ModalSell from "../modals/ModalSell";

export const ProductosTable = ({ estado }) => {
  const [{ triggerProductos }] = useStateValue();
  const { data, loading } = usePostRequest("/products/productos", { estado }, [triggerProductos]);
  // Estados
  // Numero | Descripcion
  // 1      | Comprado
  // 2      | Llegado
  // 3      | Publicado
  // 4      | Vendido
  const columns = () => {
    switch (estado) {
      case 1:
        return [
          {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
            sorter: (a, b) => a.nombre.localeCompare(b.nombre),
          },
          {
            title: "Fecha de compra",
            dataIndex: "fecha_compra",
            key: "fecha_compra",
            sorter: (a, b) => a.fecha_compra.localeCompare(b.fecha_compra),
            render: (text) => moment(text).format("DD [de] MMMM [de] YYYY")
          },
          {
            title: "Precio de compra ",
            dataIndex: "precio_compra",
            key: "precio_compra",
            render: (text) => ("$" + text)
          },
          {
            align: "center",
            title: "Llegado",
            dataIndex: "id",
            key: "llegado",
            render: (text) => <ModalArrived id={text} />
          },
          {
            align: "center",
            title: "Eliminar",
            dataIndex: "id",
            key: "id",
            render: (text) => <DeleteProducto id={text} />
          }
        ];
      case 2:
        return [
          {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
            sorter: (a, b) => a.nombre.localeCompare(b.nombre),
          },
          {
            title: "Fecha de compra",
            dataIndex: "fecha_compra",
            key: "fecha_compra",
            sorter: (a, b) => a.fecha_compra.localeCompare(b.fecha_compra),
            render: (text) => moment(text).format("DD [de] MMMM [de] YYYY")
          },
          {
            title: "Precio de compra ",
            dataIndex: "precio_compra",
            key: "precio_compra",
            render: (text) => ("$" + text)
          },
          {
            title: "Fecha de llegada",
            dataIndex: "fecha_llegada",
            key: "fecha_llegada",
            sorter: (a, b) => a.fecha_compra.localeCompare(b.fecha_compra),
            render: (text) => moment(text).format("DD [de] MMMM [de] YYYY")
          },
          {
            title: "Precio de envío ",
            dataIndex: "precio_envio",
            key: "precio_envio",
            render: (text) => ("$" + text)
          },
          {
            title: "Precio total",
            dataIndex: "precio_envio",
            key: "precio_total",
            render: (text, records) => ("$" + (records.precio_envio + records.precio_compra))
          },
          {
            title: "Publicar",
            dataIndex: "id",
            key: "publicar",
            render: (text, records) => <ModalPublish data={records} />
          }

        ];
      case 3:
        return [
          {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
            sorter: (a, b) => a.nombre.localeCompare(b.nombre),
          },
          {
            title: "Precio de compra ",
            dataIndex: "precio_compra",
            key: "precio_compra",
            render: (text) => ("$" + text)
          },
          {
            title: "Precio de envío ",
            dataIndex: "precio_envio",
            key: "precio_envio",
            render: (text) => ("$" + text)
          },
          {
            title: "Precio total",
            dataIndex: "precio_envio",
            key: "precio_total",
            render: (text, records) => ("$" + (records.precio_envio + records.precio_compra))
          },
          {
            title: "Fecha de publicación",
            dataIndex: "fecha_publicacion",
            key: "fecha_publicacion",
            sorter: (a, b) => a.fecha_compra.localeCompare(b.fecha_compra),
            render: (text) => moment(text).format("DD [de] MMMM [de] YYYY")
          },
          {
            title: "Pausar",
            dataIndex: "id",
            key: "pausar",
            render: (text) => <PauseProduct id={text} />
          },
          {
            title: "Vender",
            dataIndex: "id",
            key: "vender",
            render: (text) => <ModalSell id={text} />
          }
        ];
      case 4:
        return [
          {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
            sorter: (a, b) => a.nombre.localeCompare(b.nombre),
          },
          {
            title: "Fecha de compra",
            dataIndex: "fecha_compra",
            key: "fecha_compra",
            sorter: (a, b) => a.fecha_compra.localeCompare(b.fecha_compra),
            render: (text) => moment(text).format("DD [de] MMMM [de] YYYY")
          },
          {
            title: "Precio de compra ",
            dataIndex: "precio_compra",
            key: "precio_compra",
            render: (text) => ("$" + text)
          },
          {
            title: "Eliminar",
            dataIndex: "id",
            key: "id",
            render: (text) => <DeleteProducto id={text} />
          }
        ];
      case 5:
        return [
          {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
            sorter: (a, b) => a.nombre.localeCompare(b.nombre),
          },
          {
            title: "Fecha de compra",
            dataIndex: "fecha_compra",
            key: "fecha_compra",
            sorter: (a, b) => a.fecha_compra.localeCompare(b.fecha_compra),
            render: (text) => moment(text).format("DD [de] MMMM [de] YYYY")
          },
          {
            title: "Precio de compra ",
            dataIndex: "precio_compra",
            key: "precio_compra",
            render: (text) => ("$" + text)
          },
          {
            title: "Eliminar",
            dataIndex: "id",
            key: "id",
            render: (text) => <DeleteProducto id={text} />
          }
        ];
      default:
        return null;
    }
  }

  return (
    <Table
      loading={loading}
      columns={columns()}
      dataSource={data}
      rowKey="id"
    />
  );
}
