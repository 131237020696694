import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../animations/paginaEnConstruccion.json'
import colors from '../../styles/colors';

export const Landing = () => {
    return (
        <div style={{ backgroundColor: "white", overflowX: "hidden", height: "100%" }}>
            <div className='d-flex flex-row flex-wrap text-center justify-content-center align-items-center h-100'>
                <div
                    className='d-flex flex-column justify-content-center align-items-center p-5'
                >
                    <span style={{ fontSize: "4rem", color: colors.primary }}>Página en</span>
                    <span style={{ fontSize: "4rem", color: colors.primary }}>construcción</span>
                </div>
                <div>
                    <Lottie
                        className='d-flex'
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                        }}
                        height={350}
                        width={700}
                    />
                </div>
            </div>
        </div>
    );

}
