import React, { useState } from 'react';
import { DrawerProductos } from '../../components/admins/body/drawers/DrawerProductos';
import { ProductosTable } from '../../components/admins/body/tables/ProductosTable';
import { Radio } from "antd";
export const Productos = () => {
    const [estado, setEstado] = useState(1)
    // Estados
    // Numero | Descripcion
    // 1      | Comprado
    // 2      | Llegado
    // 3      | Publicado
    // 4      | Vendido
    return (
        <>
            <div className="d-flex justify-content-between mb-3 align-items-center">
                <h4>Productos</h4>
                <DrawerProductos />
            </div>
            <div className="d-flex justify-content-between">
                <Radio.Group onChange={e => setEstado(e.target.value)} defaultValue={estado} className="mb-3">
                    <Radio.Button value={1}>Comprados</Radio.Button>
                    <Radio.Button value={2}>Llegados</Radio.Button>
                    <Radio.Button value={3}>Publicados</Radio.Button>
                    <Radio.Button value={4}>Vendidos</Radio.Button>
                    <Radio.Button value={5}>Pausados</Radio.Button>
                </Radio.Group>
            </div>
            <ProductosTable estado={estado} />
        </>
    );

}