import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useUserInfo } from './hooks/useUserInfo';
import { Home } from './pages/users';
import { HomeAdmin } from './pages/admin';
import 'antd/dist/antd.css';
import { useStateValue } from './providers/StateProvider';

export default function App() {
  console.log(useStateValue())
  const [{ admin }] = useStateValue();
  console.log(useStateValue())
  useUserInfo();
  return (
    <Router>
      <Switch>
        <Route path="/">
          {admin ? <HomeAdmin /> : <Home />}
        </Route>
      </Switch>
    </Router>
  );
}