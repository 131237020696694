import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Landing } from "../users"
import { LoginAdmin } from '../../components/admins';
import { Head } from './Head';
import colors from '../../styles/colors';
import { Foot } from './Foot';

const { Header, Content, Footer } = Layout;
export const Home = () => {
    return (
        <Router>
            <Layout style={{ minHeight: '100vh', flex: 7 }}>
                <Header style={{ backgroundColor: colors.primary, height: "70px", padding: "10px" }}>
                    <Head />
                </Header>
                <Content style={{ backgroundColor: "white", flex: 5, minHeight: "calc(100vh-70-100)", display: "flex", justifyContent:"center" }}>
                    <Switch >
                        <Route exact path="/" component={Landing} />
                    </Switch>
                </Content>
                <Footer style={{ backgroundColor: colors.primary, eight: "100px", padding: 0 }} >
                    <Foot />
                </Footer>
            </Layout>
        </Router >
    );
}


