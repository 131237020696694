export const initialState = {
    token: `Bearer ${localStorage.getItem("token")}`,
    nombres: null,
    id: null,
    apellido_paterno: null,
    apellido_materno: null,
    correo: null,
    cod_pais: null,
    telefono: null,
    verificado: null,
    admin: null,
    usuario: null,
    id_propietario: null
};

export default function reducer(state, action) {
    switch (action.type) {
        case 'ADD_TOKEN':
            const { token } = action.item;
            localStorage.setItem("token", token);
            return {
                ...state,
                token: `Bearer ${token}`
            }
        case 'ADD_USER_INFO':
            const { nombres, id, apellido_paterno, apellido_materno, correo, cod_pais, telefono, verificado } = action.item;

            return {
                ...state,
                nombres: nombres?.toString(),
                id: id?.toString(),
                apellido_paterno: apellido_paterno?.toString(),
                apellido_materno: apellido_materno?.toString(),
                correo: correo?.toString(),
                cod_pais: cod_pais?.toString(),
                telefono: telefono?.toString(),
                verificado: verificado?.toString()
            }
        case 'ADD_ADMIN_INFO':
            const { id_admin, nombre, usuario, id_propietario } = action.item;
            return {
                ...state,
                id: id_admin?.toString(),
                nombres: nombre?.toString(),
                usuario: usuario?.toString(),
                id_propietario: id_propietario?.toString(),
                admin: true
            }

        case 'DELETE_USER_INFO':
            localStorage.clear();
            return initialState

        case 'TRIGGER_ACTION':
            const KEY2 = `trigger${action.item}`;
            return {
                ...state,
                [KEY2]: !state[KEY2]
            }

        default:
            return state;
    }
}
