import React from 'react';
import { DrawerMarcas } from '../../components/admins/body/drawers/DrawerMarcas';
import { MarcasTable } from '../../components/admins/body/tables';

export const Marcas = () => {
    return (
        <>
            <div className="d-flex justify-content-between mb-3 align-items-center">
                <h4>Marcas</h4>
                <DrawerMarcas />
            </div>
            <MarcasTable />
        </>
    );

}