import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StateProvider } from './providers/StateProvider';
import { ConfigProvider } from 'antd';
import reducer, { initialState } from './providers/reducer';
import esES from 'antd/es/locale/es_ES';

ReactDOM.render(
  <StateProvider initialState={initialState} reducer={reducer}>
    <ConfigProvider locale={esES}>
      <App />
    </ConfigProvider>
  </StateProvider>,
  document.getElementById('root')
);

