import React from 'react';
import { Image } from 'antd';
import logo from "../../images/logoText.png"

export const Head = () => {
    return (
        <div className='d-flex justify-content-center h-100'>
            <Image height="100%" src={logo} preview={false} onClick={() => console.log("volver a inicio")} />
        </div>
    );

}
