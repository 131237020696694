import React, { useState } from "react";
import {
  Drawer,
  Form,
  Button,
  AutoComplete,
  DatePicker,
  InputNumber
} from "antd";
import {
  PlusOutlined
} from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import { catchRequest } from "../../../../utils/catchRequest";
import { useStateValue } from "../../../../providers/StateProvider";
import { usePostRequest } from '../../../../hooks';

export const DrawerProductos = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [{ token }, dispatch] = useStateValue();
  const [form, setForm] = useState({})
  const { data, loading: loadingProductosNombre } = usePostRequest("/products/nombresDisp", {}, [], visible);

  const handleChange = (e, type) => {
    setForm({ ...form, [type]: e })
  }

  const handleSubmit = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: "/products/newProducto",
      data: form,
      headers: { Authorization: token },
    })
      .then((res) => {
        setLoading(false);
        "refreshToken" in res.data &&
          dispatch({
            type: "ADD_TOKEN",
            item: { token: res.data.refreshToken },
          });
        if (res.data.ok) {
          dispatch({
            type: 'TRIGGER_ACTION',
            item: 'Productos'
          });
          Swal.fire({
            icon: "success",
            title: "¡Exito!",
            text: `Añadido con éxito`,
          }).then((result) => {
            setVisible(false)
          });
        } else {
          if (res.data.message)
            Swal.fire({
              icon: 'warning',
              title: 'Ha ocurrido un error',
              text: res.data.message
            });
          else
            Swal.fire({
              icon: "success",
              title: "¡Error!",
              text: `Ocurrió un error al procesar la solicitud`,
            }).then((result) => {
              if (result.isConfirmed) {
                setVisible(false)
              }
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        catchRequest(e, dispatch);
      });
  };

  return (
    <>

      <Button type="primary" onClick={() => setVisible(true)}>
        <PlusOutlined /> Agregar producto
      </Button>
      <Drawer
        title={`Agregar producto`}
        width={400}
        onClose={() => setVisible(loading)}
        visible={visible}
        destroyOnClose={true}
        footer={[
          <div className="d-flex justify-content-between">
            <Button onClick={() => setVisible(false)}>
              Cancelar
            </Button>
            <Button type="primary" form="myForm" key="submit" htmlType="submit">
              Agregar
            </Button>
          </div>
        ]}
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={() => null}
          requiredMark={false}
          id="myForm"
        >
          <Form.Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: 'Ingresa el nombre' }]}>
            <AutoComplete
              onChange={(e) => handleChange(e, "nombre")}
              placeholder="Microsoft Surface Pro 6"
              loading={loadingProductosNombre}
              options={data ? data?.map((value) => { return { value: value.nombre } }) : null}
              filterOption={(inputValue, option) =>
                data && option.value.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
              }
            />
          </Form.Item>
          <Form.Item
            name="precio_compra"
            label="Precio de compra"
            rules={[{ required: true, message: 'Ingresa el precio de compra' }]}>
            <InputNumber
              placeholder="500"
              onChange={(e) => handleChange(e, "precio_compra")}
            />
          </Form.Item>
          <Form.Item
            name="fecha_compra"
            label="Fecha de compra"
            rules={[{ required: true, message: 'Ingresa la fecha de compra' }]}>
            <DatePicker
              onChange={(_, f) => handleChange(f, "fecha_compra")}
              format={"YYYY/MM/DD"}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
