import React from 'react';
import { Button } from 'antd';
import { useStateValue } from "../../../providers/StateProvider"
import { useHistory } from 'react-router';

export const AdminLogout = () => {
    const [_, dispatch] = useStateValue();
    const history = useHistory();
    const handleLogout = () => {
        dispatch({ type: "DELETE_USER_INFO" })
        history.push(`/`);
    }
    return (
        <>
            <Button danger onClick={() => handleLogout()} >Cerrar sesión</Button>
        </>
    );
}