import React from "react";
import { Table } from "antd";
import { usePostRequest } from '../../../../hooks';
import { useStateValue } from "../../../../providers/StateProvider";
import { DeleteCategoria } from "../deletes/DeleteCategoria";

export const CategoriasTable = () => {
  const [{ triggerCategorias }] = useStateValue();
  const { data, loading } = usePostRequest("/products/categorias", [triggerCategorias]);

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Eliminar",
      dataIndex: "id",
      key: "id",
      render: (text, records) => (
        <DeleteCategoria id={text} />
      ),
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      loading={loading}
    />
  )
}
