import Swal from 'sweetalert2';

export const catchRequest = (err, dispatch) => {
    console.log(err)
    if (err?.message === "Network Error") {
        Swal.fire(
            'Error de red',
            'Por favor verifique su conexión a internet',
            'error'
        ).then((result) => {
        })
    } else {

        if ("message" in err && !err.message) return; // if request was canceled by user (axios cancelToken)

        if (err.response?.status === 401) {
            Swal.fire(
                'No Autorizado (401)',
                '',
                'error'
            ).then((result) => {
                result && window.location.replace("/");
            })
        }

        else if (err.response?.status === 403) {
            Swal.fire(
                'La sesión ha expirado',
                'Por favor, ingrese de nuevo a su cuenta.',
                'info'
            ).then(result => {
                result && dispatch({ type: "DELETE_USER_INFO" })
            })
        }
     
        else if (err.response?.status === 408) {
            Swal.fire(
                '¡La conexión tardó demasiado en responder!',
                'Actualiza la página y revisa tu conexión a internet.',
                'error'
            )
        }

        else if (err.response?.status === 423) {
            Swal.fire(
                'Demasiados intentos',
                'Ha realizado muchos intentos fallidos de inicio de sesión. El usuario se ha bloqueado. Por favor intente de nuevo en 15 minutos',
                'error',
            )
        }

        else if (err.response?.status === 429) {
            Swal.fire(
                'Recurso bloqueado',
                'Este servicio está bloqueado a cierto número de solicitudes mensuales, por favor, contacte al administrador. ',
                'info',
            )
        }

        else {
            Swal.fire(
                '¡Lo sentimos!',
                `Ha ocurrido un error, actualice la página y vuelva a intentarlo.`,
                'error');
        }
    }
}