import React, { useState } from "react";

import { Button, Modal, DatePicker, InputNumber, Form } from "antd";
import { DollarOutlined } from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import { catchRequest } from "../../../../utils";
import { useStateValue } from "../../../../providers/StateProvider";
import moment from 'moment';


export default function ModalSell({ id }) {
    const [visible, setVisible] = useState(false);
    const [current, setCurrent] = useState(0)
    const [{ token }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({ id });

    const handleChange = (e, type) => {
        setForm({ ...form, [type]: e });
    }

    const next = () => {
        setCurrent(current + 1)
    }
    const prev = () => {
        setCurrent(current - 1)

    }
    const handleSubmit = () => {
        console.log("enviado")
        setLoading(true);

        axios.post("/products/publicarProducto", form, { headers: { Authorization: token } })
            .then((res) => {
                setLoading(false);
                "refreshToken" in res.data &&
                    dispatch({
                        type: "ADD_TOKEN",
                        item: { token: res.data.refreshToken },
                    });
                if (res.data.ok) {
                    dispatch({
                        type: 'TRIGGER_ACTION',
                        item: 'Productos'
                    });
                    Swal.fire({
                        icon: "success",
                        title: "¡Exito!",
                        text: `Se publicó con éxito`,
                    }).then((res) => {
                        setVisible(false)
                    });
                } else {
                    if (res.data.message)
                        Swal.fire({
                            icon: 'warning',
                            title: 'Ha ocurrido un error',
                            text: res.data.message
                        });
                    else
                        Swal.fire({
                            icon: "success",
                            title: "¡Error!",
                            text: `Ocurrió un error al procesar la solicitud`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setVisible(false)

                            }
                        });
                }
            })
            .catch((e) => {
                setLoading(false);
                catchRequest(e, dispatch);
            });
    };


    return (
        <>
            <Button type="primary" icon={<DollarOutlined />} onClick={() => setVisible(true)} />
            <Modal
                destroyOnClose={true}
                title={`Marcar como llegado`}
                onCancel={() => setVisible(false)}
                onOk={() => setVisible(false)}
                visible={visible}
                footer={[
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => setVisible(false)}>
                            Cancelar
                        </Button>
                        <Button loading={loading} type="primary" form="myForm" key="submit" htmlType="submit">
                            Guardar
                        </Button>
                    </div>
                ]}
            >
                <div className="d-flex justify-content-center">
                    <Form
                        layout="vertical"
                        onFinish={handleSubmit}
                        onFinishFailed={() => null}
                        requiredMark={false}
                        id="myForm"

                    >

                        <Form.Item
                            name="precio"
                            label="Precio de envío"
                            rules={[{ required: true, message: 'Ingrese el precio de envío' }]}>
                            <InputNumber
                                onChange={(e) => handleChange(e, "precio_envio")}
                            />
                        </Form.Item>
                        <Form.Item
                            name="fecha_llegada"
                            label="Fecha de llegada"
                        >
                            <DatePicker
                                onChange={(_, f) => handleChange(f, "fecha_llegada")}
                                defaultValue={moment()}
                                format={"YYYY/MM/DD"}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}
