import React, { useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Input,
  DatePicker,
  InputNumber
} from "antd";
import {
  PlusOutlined
} from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import { catchRequest } from "../../../../utils/catchRequest";
import { useStateValue } from "../../../../providers/StateProvider";

export const DrawerMarcas = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [{ token }, dispatch] = useStateValue();
  const [form, setForm] = useState({})

  const handleChange = (e, type) => {
    setForm({ ...form, [type]: e })
  }

  const handleSubmit = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: "/products/newMarca",
      data: form,
      headers: { Authorization: token },
    })
      .then((res) => {
        setLoading(false);
        "refreshToken" in res.data &&
          dispatch({
            type: "ADD_TOKEN",
            item: { token: res.data.refreshToken },
          });
        if (res.data.ok) {
          dispatch({
            type: 'TRIGGER_ACTION',
            item: 'Marcas'
          });
          Swal.fire({
            icon: "success",
            title: "¡Exito!",
            text: `Añadido con éxito`,
          }).then((result) => {
            setVisible(false)
          });
        } else {
          if (res.data.message)
            Swal.fire({
              icon: 'warning',
              title: 'Ha ocurrido un error',
              text: res.data.message
            });
          else
            Swal.fire({
              icon: "success",
              title: "¡Error!",
              text: `Ocurrió un error al procesar la solicitud`,
            }).then((result) => {
              if (result.isConfirmed) {
                setVisible(false)
              }
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        catchRequest(e, dispatch);
      });
  };

  return (
    <>

      <Button type="primary" onClick={() => setVisible(true)}>
        <PlusOutlined /> Agregar marca
      </Button>
      <Drawer
        title={`Crear marca`}
        width={400}
        onClose={() => setVisible(loading)}
        visible={visible}
        destroyOnClose={true}
        footer={[
          <div className="d-flex justify-content-between">
            <Button onClick={() => setVisible(false)}>
              Cancelar
            </Button>
            <Button type="primary" form="myForm" key="submit" htmlType="submit">
              Crear
            </Button>
          </div>
        ]}
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={() => null}
          requiredMark={false}
          id="myForm"
        >
          <Form.Item
            name="marca"
            label="Marca"
            rules={[{ required: true, message: 'Ingresa la marca' }]}>
            <Input
              placeholder="Microsoft"
              onChange={(e) => handleChange(e.target.value, "nombre")}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
