import React, { useState } from "react";

import { Button, Modal, Steps, message, Input, InputNumber, Upload, Form } from "antd";
import { CloudUploadOutlined, InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import { catchRequest } from "../../../../utils";
import { useStateValue } from "../../../../providers/StateProvider";


export default function ModalPublish({ data }) {
    const [visible, setVisible] = useState(false);
    const [current, setCurrent] = useState(0)
    const [{ token }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({ id: data.id });
    
    const handleChange = (e, type) => {
        setForm({ ...form, [type]: e });
    }

    const next = () => {
        setCurrent(current + 1)
    }
    const prev = () => {
        setCurrent(current - 1)

    }
    const handleSubmit = () => {
        console.log("enviado")
        setLoading(true);

        axios.post("/products/publicarProducto", form, { headers: { Authorization: token } })
            .then((res) => {
                setLoading(false);
                "refreshToken" in res.data &&
                    dispatch({
                        type: "ADD_TOKEN",
                        item: { token: res.data.refreshToken },
                    });
                if (res.data.ok) {
                    dispatch({
                        type: 'TRIGGER_ACTION',
                        item: 'Productos'
                    });
                    Swal.fire({
                        icon: "success",
                        title: "¡Exito!",
                        text: `Se publicó con éxito`,
                    }).then((res) => {
                        setVisible(false)
                    });
                } else {
                    if (res.data.message)
                        Swal.fire({
                            icon: 'warning',
                            title: 'Ha ocurrido un error',
                            text: res.data.message
                        });
                    else
                        Swal.fire({
                            icon: "success",
                            title: "¡Error!",
                            text: `Ocurrió un error al procesar la solicitud`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setVisible(false)

                            }
                        });
                }
            })
            .catch((e) => {
                setLoading(false);
                catchRequest(e, dispatch);
            });
    };
    const uploadPicture = (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        let formData = new FormData();
        formData.append("id", data.id)
        formData.append("archivo", file)
        const config = {
            headers: { Authorization: token },
            onUploadProgress: progressEvent => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onProgress({ percent: percentCompleted });

            }
        }
        axios.post("/products/newPicture", formData, config)
            .then((res) => {
                setLoading(false);
                "refreshToken" in res.data &&
                    dispatch({
                        type: "ADD_TOKEN",
                        item: { token: res.data.refreshToken },
                    });
                if (res.data.ok) {
                    onSuccess("OK")
                } else {
                    const error = new Error(res.data?.message);
                    onError({ error });
                }
            })
            .catch((e) => {
                onError({ e });
            });
    }

    return (
        <>
            <Button type="primary" icon={<CloudUploadOutlined />} onClick={() => setVisible(true)} />

            <Modal
                destroyOnClose={true}
                title={`Publicar`}
                onCancel={() => setVisible(false)}
                onOk={() => setVisible(false)}
                visible={visible}
                width={1000}
                style={{ top: 10 }}
                footer={false}

            >
                <div className="d-flex flex-column justify-content-between" style={{ minHeight: "80vh" }}>
                    <div>
                        <Steps current={current}>
                            <Steps.Step title="Descripción" />
                            <Steps.Step title="Precio" />
                            <Steps.Step title="Fotos" />
                            <Steps.Step title="Publicar" />

                        </Steps>
                        {current === 0 && (
                            <Input.TextArea
                                defaultValue={form.descripcion && form.descripcion}
                                className="mt-5"
                                rows={15}
                                placeholder=" Apple Watch Serie 1 38mm
                                         En muy buenas condiciones
                                         Sin detalles  
                                         Sin cuentas"
                                onChange={(e) => handleChange(e.target.value, "descripcion")}
                            />
                        )}
                        {current === 1 && (
                            <div className="text-center m-5">
                                <div className="text-center m-5">
                                    <span className="m-3" >Precio al público</span>
                                    <InputNumber
                                        defaultValue={form.precio && form.precio}
                                        placeholder="600"
                                        onChange={(e) => handleChange(e, "precio")}
                                    />
                                </div >
                                <div className="text-center m-5">
                                    <span className="m-3" >¿El artículo tendrá algún descuento?</span>
                                    <InputNumber
                                        maxLength={2}
                                        defaultValue={form.descuento && form.descuento}
                                        placeholder="10%"
                                        onChange={(e) => handleChange(e, "descuento")}
                                    />
                                </div>

                            </div>
                        )}
                        {current === 2 && (
                            <div className="m-5" style={{ maxHeight: "55vh", overflow: "auto" }}>
                                <Upload.Dragger
                                    customRequest={(e) => uploadPicture(e)}
                                    multiple={true}
                                    listType="picture"
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Haz clic o suelta los archivos en esta área</p>
                                    <p className="ant-upload-hint">
                                        Al publicarse, las fotos se ordenan por nombre. Se recomienda utilizar solo numeros.
                                    </p>
                                </Upload.Dragger>
                            </div>
                        )}
                        {current === 3 && (
                            <div className="m-5" style={{ maxHeight: "55vh", overflow: "auto" }}>
                                <div className="d-flex flex-row justify-content-center">
                                    <Form
                                        layout="vertical"
                                        onFinish={handleSubmit}
                                        onFinishFailed={() => null}
                                        requiredMark={false}
                                        id="myForm"
                                        initialValues={{
                                            ["nombre"]: [data?.nombre],
                                            ["descripcion"]: [form.descripcion],
                                            ["precio"]: [form.precio],
                                            ["descuento"]: [form.descuento && form.descuento],

                                        }}
                                    >
                                        <Form.Item
                                            name="nombre"
                                            label="Nombre"
                                            rules={[{ required: true, message: 'Ingrese el nombre' }]}>
                                            <Input
                                                placeholder="Nombre"
                                                onChange={(e) => handleChange(e.target.value, "nombre")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="precio"
                                            label="Precio al público"
                                            rules={[{ required: true, message: 'Ingrese el precio' }]}>
                                            <InputNumber
                                                onChange={(e) => handleChange(e.target.value, "descripcion")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="descuento"
                                            label="Descuento"
                                        >
                                            <InputNumber
                                                onChange={(e) => handleChange(e.target.value, "descuento")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="descripcion"
                                            label="Descripción"
                                            rules={[{ required: true, message: 'Ingrese la descripcion' }]}>
                                            <Input.TextArea
                                                onChange={(e) => handleChange(e.target.value, "descripcion")}
                                            />
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        )}

                    </div>
                    <div className="d-flex flex-row-reverse">
                        {current === 0 && (
                            <Button type="primary" disabled={!form.descripcion} onClick={() => next()}>
                                Siguiente
                            </Button>
                        )}
                        {current === 1 && (
                            <div>
                                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                    Anterior
                                </Button>
                                <Button type="primary" disabled={!form.precio} onClick={() => next()}>
                                    Siguiente
                                </Button>
                            </div>
                        )}
                        {current === 2 && (
                            <div>
                                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                    Anterior
                                </Button>
                                <Button type="primary" onClick={() => next()}>
                                    Siguiente
                                </Button>
                            </div>
                        )}
                        {current === 3 && (
                            <div>
                                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                    Anterior
                                </Button>
                                <Button type="primary" form="myForm" htmlType="submit" >
                                    Finalizar
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
}
