import React from 'react';
import { DrawerCategorias } from '../../components/admins/body/drawers';
import { CategoriasTable } from '../../components/admins/body/tables';

export const Categorias = () => {
    return (
        <>
            <div className="d-flex justify-content-between mb-3 align-items-center">
                <h4>Categorias</h4>
                <DrawerCategorias />
            </div>
            <CategoriasTable />
        </>
    );

}