import { Button, Menu, Card, Dropdown, Avatar } from 'antd';
import React, { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useStateValue } from '../../../providers/StateProvider';
import { AdminLogout } from './';

export const AdminInfo = () => {
    const [{ nombre }] = useStateValue();
    const menuLogged = (
        <Card style={cardStyle}>
            <div>
                <Avatar size="large" icon={<UserOutlined />} />
                <span className="m-3">{`${nombre} `}</span>
                <div className="d-flex flex-column mt-2 align-items-center justify-content-center">
                    <AdminLogout />
                </div>
            </div>
        </Card>
    );


    return (
        <div>
            <Dropdown
                overlay={menuLogged}
                placement="bottomLeft"
                trigger={['click']}
            >
                <Button shape="circle" icon={<UserOutlined />}></Button>
            </Dropdown>
        </div>
    );

}
const cardStyle = {
    width: "300px",
    padding: "1rem"
}
const spanStyle = {
    color: "white",
    marginLeft: "5px"
}