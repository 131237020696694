import React from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';
import { useStateValue } from '../../../../providers/StateProvider';
import { catchRequest } from '../../../../utils';
import { Button } from 'antd';

export const DeleteProducto = ({ id }) => {

    const [{ token }, dispatch] = useStateValue();

    const onClickButton = () => {
        Swal.fire({
            title: 'Eliminar producto',
            text: ``,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonColor: '#ff4d4f',
            cancelButtonColor: '#009BFF',
            confirmButtonText: 'Borrar',

        }).then((result) => {
            if (result.value) {
                handleDelete();
            }
        })
    }

    const handleDelete = () => {
        const config = {
            headers: { Authorization: token }
        };

        axios.post("/products/deleteProducto", { id }, config)
            .then((res) => {
                "refreshToken" in res.data && dispatch({ type: 'ADD_TOKEN', item: { token: res.data.refreshToken } });
                if (res.data.ok) {
                    dispatch({
                        type: 'TRIGGER_ACTION',
                        item: 'Productos'
                    });
                    Swal.fire({
                        icon: "success",
                        title: "¡Exito!",
                        text: `Producto eliminado con éxito`,
                    })
                } else {
                    if (res.data.message)
                        Swal.fire({
                            icon: 'warning',
                            title: 'Ha ocurrido un error',
                            text: res.data.message
                        });
                    else
                        Swal.fire({
                            icon: 'warning',
                            title: 'Ha ocurrido un error',
                            text: "Tuvimos problemas para procesar su solicitud. Por favor intente más tarde"
                        });
                }
            })
            .catch((e) => {
                catchRequest(e, dispatch);
            })
    };

    return <Button type="primary" icon={<DeleteOutlined />} danger onClick={() => onClickButton()} />
}