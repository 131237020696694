import React, { useState } from 'react';
import { FacebookFilled, InstagramFilled, MailFilled, PhoneFilled } from '@ant-design/icons';
import moment from "moment"
import colors from '../../styles/colors';
import { Divider } from 'antd';
import '../../styles/animations.css'

export const Foot = () => {
    return (
        <div style={{ backgroundColor: colors.footer }}>
            <div className='d-flex justify-content-center'>
                <h2 className='mt-3 text-white'>Contáctanos</h2>
            </div>
            <div className='d-flex flex-row justify-content-center flex-wrap'>
                <div className='d-flex flex-column'>
                    <div className="d-flex flex-row justify-content-center p-3">
                        <FacebookFilled
                            className='mx-2 tada'
                            onClick={() => window.open("https://facebook.com/aotekmx")}
                            style={{ fontSize: "2rem", color: "white" }}
                        />
                        <InstagramFilled
                            className='mx-2 tada'
                            onClick={() => window.open("https://instagram.com/aotek.mx")}
                            style={{ fontSize: "2rem", color: "white" }} />
                        <a href="mailto: contacto@aotek.com.mx"><MailFilled className='mx-2 tada' style={{ fontSize: "2rem", color: "white" }} /></a>
                        <a href="tel:6181807434"> <PhoneFilled className='mx-2 tada' style={{ fontSize: "2rem", color: "white" }} /></a>
                    </div >
                </div>
                <div className='d-flex flex-column align-items-center '>
                    <h5 className='text-white'>618-180-7434</h5>
                    <h5 className='text-white'>contacto@aotek.com.mx</h5>
                </div>
            </div>
            <div className='d-flex flex-row justify-content-center align-items-center flex-wrap text-white' style={{ minHeight: "40px", borderTopLeftRadius: 5, backgroundColor: colors.footerDark }}>
                <a>Condiciones de uso</a>
                <Divider style={{ backgroundColor: "white" }} type="vertical" />
                <a>Política de privacidad</a>
                <Divider style={{ backgroundColor: "white" }} type="vertical" />
                <span>
                    {`Copyright ©. ${moment().format("YYYY")} Todos los derechos reservados.`}
                </span>
            </div>
        </div>
    );

}
