import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SidebarAdmin } from '../../layouts/admin';
import { Productos } from './Productos';
import { AdminInfo } from '../../components/admins/header';
import { useStateValue } from '.././../providers/StateProvider';
import { Marcas } from './Marcas';
import { Categorias } from './Categorias';

const { Header, Content, Footer } = Layout;

export const HomeAdmin = () => {
    const [{ nombres }] = useStateValue();
    console.log(useStateValue())
    return (
        <Router>
            <Layout style={{ minHeight: '100vh' }}>
                <SidebarAdmin />
                <Layout>
                    <Header style={headerStyle}>
                        <h4 style={{ color: "white" }}>{nombres}</h4>
                        <AdminInfo />
                    </Header>
                    <Content style={contentStyle}>
                        <Switch>
                            <Route exact path="/productos" component={Productos} />
                            <Route exact path="/marcas" component={Marcas} />
                            <Route exact path="/categorias" component={Categorias} />
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </Router>
    );
}
const headerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
}
const contentStyle = {
    margin: '24px 16px',
    padding: 24,
    minHeight: "calc(100vh - 130px)",
    background: "#fff"
}

