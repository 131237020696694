import React from "react";
import { useHistory } from "react-router";
import { Menu, Layout } from "antd";
import {
    DashboardOutlined,
    UserOutlined,
    AuditOutlined,
    BankOutlined,
    CalendarOutlined,
    TeamOutlined,
    SafetyOutlined,
    DollarOutlined,
    UserSwitchOutlined,
    SafetyCertificateOutlined,
    PlayCircleOutlined,
    FileTextOutlined,
    CarryOutOutlined,
    MessageOutlined

} from "@ant-design/icons";
import "moment/locale/es-us";
import { Scrollbars } from 'react-custom-scrollbars';

export const SidebarAdmin = () => {
    const { SubMenu } = Menu;
    const { Sider } = Layout;
    const history = useHistory();


    const handleMenu = (route) => {
        history.push(`/${route}`);
    };


    return (
        <Sider >
            <div style={{
                height: 64,
                // backgroundImage: `url(${collapsed ? logo2 : logo})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                filter: "dark",
                transition: "0.25s"
            }} />
            <Scrollbars
                style={{ width: "auto" }}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                thumbMinSize={30}
                universal={true}
            >
                <Menu theme="dark" mode="inline">
                    <Menu.Item  key="1" onClick={() => handleMenu("productos")} icon={<UserOutlined />}>Productos</Menu.Item>
                    <Menu.Item  key="2" onClick={() => handleMenu("marcas")} icon={<UserOutlined />}>Marcas</Menu.Item>
                    <Menu.Item  key="3" onClick={() => handleMenu("categorias")} icon={<UserOutlined />}>Categorias</Menu.Item>
                </Menu>
            </Scrollbars>
        </Sider>
    );

}
